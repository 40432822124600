.modal {
  height: 100vh;
  background: #222;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  padding: 12px;
  z-index: 4;
}
