nav {
  position: fixed;
  top: 0;
  left: 0;
  background: #111;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  button {
    width: fit-content;
    padding: 0;
    background: transparent;
    padding: 18px;
    color: #fff;
    svg {
      margin: 0;
    }
  }
}
.input {
  width: 0;
  transition: 0.35s;
  opacity: 0;
  input {
    width: 0;
    padding: 14px 0 !important;
    margin: 2px 0 2px 12px;
  }
  &.show {
    opacity: 1;
    width: calc(100% - 75px);
    transition: linear 0.35s;
    padding: initial;
    input {
      width: 100%;
      padding: 14px !important;
      transition: 0.35s;
    }
  }
}
