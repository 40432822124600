@import url("https://fonts.googleapis.com/css2?family=Coiny&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html,
#root,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: #fff;
  background: #222;
  font-family: "Montserrat";
}
.app {
  overflow: auto !important;
  min-height: calc(100vh - 75px);
  max-height: calc(100vh - 75px);
  position: relative;
}
h1,
h2,
h3,
h4,
h5,
p,
small,
label {
  color: #fff;
}
a {
  text-decoration: none;
  width: fit-content;
  height: fit-content;
}
.page {
  padding: 75px 12px 12px 12px;
  text-align: left;
  width: 100%;
  max-width: 500px !important;
  margin: 0 auto;
  box-sizing: border-box;
  .pagePost {
    img {
      width: 100%;
      aspect-ratio: 1/1;
      background: #f9f9f9;
      border-radius: 20px;
    }
  }
}
.swiper {
  padding: 0 0 40px 0;
}
.swiper-slide {
  height: 300px;
  background: #eee;
}
.swiper-pagination-bullet-active {
  background: #d50032;
}
.card {
  position: relative;
  background: transparent;
  overflow: hidden;
  text-align: left;
  margin: 0 auto 16px auto;
  display: block;
  height: fit-content;
  min-height: fit-content;
  display: block;
  box-sizing: border-box;
  padding: 0;
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    font-size: 0;
    background: #333;
    border-radius: 20px;
  }
  .cardText {
    position: relative;
    box-sizing: border-box;
    color: #222;
    a {
      color: #fff;
    }
    h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 12px 0 0 0;
      button {
        width: 30px;
        height: 30px;
        padding: 0;
        background-color: transparent;
        svg {
          width: 100%;
          height: 100%;
          margin: 0;
          color: #fff;
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      border-radius: 50px;
      color: #999;
      margin: 0 0 12px 0 !important;
    }
  }
  .flexBox {
    border-top: 1px solid #eee;
    padding: 5px 0 0 0;

    button {
      padding-bottom: 0;
      text-align: center;
      width: fit-content;
    }
    &.blog {
      width: 100%;
      justify-content: center !important;
      button {
        width: 100%;
      }
    }
  }
}
.flexBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  &.tags {
    justify-content: initial;
    border: none;
    padding: 0;
  }
  button {
    background: transparent;
    color: #999;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
      font-size: 24px;
    }
  }
  p {
    margin: 0 5px 5px 0 !important;
  }
}
.imgPlaceholder {
  width: 80%;
  margin: 0 auto;
  padding-left: 15px;
}
.textCenter {
  display: block;
  text-align: center;
}
.blogContent {
  min-height: 300px;
  max-height: 100%;
  overflow: auto;
  border-radius: 20px;
  img {
    border-radius: 20px;
    object-fit: contain;
  }
}
input,
textarea,
select,
button {
  outline: none;
}
input,
textarea,
select {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  margin: 5px 0 0 0;
  border: 2px solid #999;
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Montserrat";
  background: #222;
  color: #fff;
}
form {
  text-align: left;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  label {
    display: block;
    font-weight: 500;
    color: #fff;
  }

  div {
    background: #333;
    margin: 5px 0;
    padding: 5px;
    border-radius: 8px;
    &.flex {
      padding: 12px;
      label {
        display: flex;
      }
      input {
        width: fit-content;
        margin: 0 8px 0 0;
      }
    }
  }
  .blogForm {
    background: transparent;
  }
}
button {
  width: 100%;
  max-width: 500px;
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  border: none;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  &.btnList {
    background: transparent;
    color: #fff;
    font-size: 16px;
    text-align: left;
    padding: 0;
    margin: 0;
    width: fit-content;
  }
  svg {
    margin: 0 8px 0 0;
    display: block;
  }
  &:disabled {
    opacity: 0.3;
  }
  &.btnTertiary {
    background: transparent;
    color: #fff;
    &.red {
      color: #d50032;
    }
  }
}
img {
  width: 100%;
  margin: 0 auto;
  display: block;
  font-size: 0;
}
.formHeader {
  display: flex;
  align-items: center;
  select {
    border: none;
    width: fit-content;
    background: #222;
    font-size: 23px;
    font-weight: 700;
    font-family: "Montserrat";
    outline: none;
    padding: 0 10px 0 0;
    color: #fff;
    margin: 0;
  }
}
.headerHome {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
  &.h2 {
    font-family: "Montserrat";
    font-size: 1.2rem;
    font-weight: 600;
    padding: 30px 20px;
    color: #777;
  }
}
.btnLogin {
  background: #d50032;
  backdrop-filter: blur(10px);
  border: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: fit-content;
  border-radius: 50%;
  z-index: 4;
  &.absolute {
    position: absolute;
    bottom: calc(100% - 65px);
    right: 15px;
    background: #111;
    z-index: 1;
    width: 50px;
    height: 50px;
    svg {
      color: #fff;
    }
  }
  svg {
    width: fit-content;
    display: block;
    font-size: 40px;
    color: #fff;
    margin: 0 !important;
  }
}

footer {
  background: #111;
  width: calc(100% - 24px);
  border-radius: 8px;
  margin: 0 12px;
  ul {
    list-style: none;
    padding: 8px;
    text-align: left;
    li {
      padding: 12px;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: #fff;
      svg {
        margin: 0 16px 0 0;
        display: block;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.blogContentItem {
  position: relative;
  .blogContentRemoveBtn {
    width: fit-content;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: transparent;
    border-radius: 0;
    svg {
      margin: 0;
    }
  }
}
table {
  width: 100%;
}
.searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 12px 0;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  z-index: 3;
  background: #222;
  box-shadow: 0 -17px 17px #222;
  input {
    background: #111;
    margin: 0;
    width: calc(100% - 48px);
  }
  button {
    width: 43px;
    height: 43px;
    background: #111;
    color: #fff;
    padding: 8px;
    svg {
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }
}
